var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FormWrapper', [_c('label', [_vm._v("Id")]), _c('input', {
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.asset.id
    }
  }), _c('label', [_vm._v("Name")]), _c('input', {
    attrs: {
      "disabled": true
    },
    domProps: {
      "value": _vm.asset.name
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }