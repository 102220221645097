var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreator', {
    attrs: {
      "title": 'Duplicate asset',
      "loading": _vm.isSourceAssetLoading || _vm.isSubmitLoading,
      "errors": [],
      "canCreate": _vm.canSubmit,
      "createLabel": 'duplicate'
    },
    on: {
      "create": _vm.handleSubmit
    }
  }, [_vm.sourceAssetCurrent ? _c('DuplicateAssetLayout', [_c('div', [_c('h4', [_vm._v("Source Asset")]), _c('AssetDisabledForm', {
    attrs: {
      "asset": _vm.sourceAssetCurrent
    }
  }), _c('Multiselect', {
    attrs: {
      "loading": _vm.isSourceAssetsLoading,
      "options": _vm.sourceAssetOptions,
      "value": null,
      "multiple": false,
      "preserveSearch": true,
      "trackBy": "id",
      "label": "name",
      "placeholder": 'Type to search',
      "internalSearch": false
    },
    on: {
      "select": _vm.handleNewSourceAssetSelect,
      "search-change": _vm.handleSearchChange
    }
  }), _c('DuplicatedEntities', {
    attrs: {
      "duplicatedEntities": _vm.duplicatedEntities
    },
    on: {
      "update:duplicatedEntities": function updateDuplicatedEntities($event) {
        _vm.duplicatedEntities = $event;
      },
      "update:duplicated-entities": function updateDuplicatedEntities($event) {
        _vm.duplicatedEntities = $event;
      }
    }
  })], 1), _c('TargetAssets', {
    attrs: {
      "sourceAssetId": _vm.sourceAssetRouteId,
      "targetAssetsSelected": _vm.targetAssetsSelected
    },
    on: {
      "update:targetAssetsSelected": function updateTargetAssetsSelected($event) {
        _vm.targetAssetsSelected = $event;
      },
      "update:target-assets-selected": function updateTargetAssetsSelected($event) {
        _vm.targetAssetsSelected = $event;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }