<template>
  <ModelCreator
    :title="'Duplicate asset'"
    :loading="isSourceAssetLoading || isSubmitLoading"
    :errors="[]"
    :canCreate="canSubmit"
    @create="handleSubmit"
    :createLabel="'duplicate'"
  >
    <DuplicateAssetLayout v-if="sourceAssetCurrent">
      <div>
        <h4>Source Asset</h4>
        <AssetDisabledForm :asset="sourceAssetCurrent" />
        <Multiselect
          :loading="isSourceAssetsLoading"
          :options="sourceAssetOptions"
          :value="null"
          :multiple="false"
          :preserveSearch="true"
          trackBy="id"
          label="name"
          :placeholder="'Type to search'"
          :internalSearch="false"
          @select="handleNewSourceAssetSelect"
          @search-change="handleSearchChange"
        />
        <DuplicatedEntities :duplicatedEntities.sync="duplicatedEntities" />
      </div>
      <TargetAssets :sourceAssetId="sourceAssetRouteId" :targetAssetsSelected.sync="targetAssetsSelected" />
    </DuplicateAssetLayout>
  </ModelCreator>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ModelCreator from '@/components/misc/ModelCreater'
import { styled } from '@egoist/vue-emotion'
import { debounce } from 'lodash'
import AssetDisabledForm from './DuplicateAsset/AssetDisabledForm'
import TargetAssets from './DuplicateAsset/TargetAssets'
import DuplicatedEntities from './DuplicateAsset/DuplicatedEntities'
import { FlashMessages } from '@common/singletons'

import SOURCE_ASSET_CURRENT_QUERY from '#/graphql/operations/asset/sourceAssetCurrent.gql'

import SOURCE_ASSETS_QUERY from '#/graphql/operations/assets/sourceAssets.gql'

import DUPLICATE_ASSET_MUTATION from '#/graphql/operations/asset/duplicateAsset.gql'

const DuplicateAssetLayout = styled('div')`
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
`

export default {
  components: {
    Multiselect,
    ModelCreator,
    DuplicateAssetLayout,
    AssetDisabledForm,
    TargetAssets,
    DuplicatedEntities,
  },
  data() {
    return {
      isSubmitLoading: false,
      isSourceAssetsLoading: false,
      sourceAssetCurrent: null,
      targetAssetsSelected: [],
      sourceAssetOptions: [],
      searchQuery: '',
      duplicatedEntities: [
        {
          value: 'organizations',
          label: 'Organizations',
          isSelected: true,
        },
        {
          value: 'subsidiaries',
          label: 'Subsidiaries',
          isSelected: true,
        },
        {
          value: 'oem',
          label: 'OEM',
          isSelected: true,
        },
        {
          value: 'site',
          label: 'Site',
          isSelected: true,
        },
      ],
    }
  },
  methods: {
    handleSearchChange: debounce(function (searchQuery) {
      this.searchQuery = searchQuery
    }, 300),
    handleNewSourceAssetSelect(selectedAsset) {
      this.$router.push({
        name: 'duplicateAsset',
        params: {
          id: selectedAsset.id,
        },
      })
    },
    async handleSubmit() {
      try {
        this.isSubmitLoading = true
        const properties = this.duplicatedEntities.reduce((acc, item) => ({ ...acc, [item.value]: item.isSelected }), {})

        await this.$apollo.mutate({
          mutation: DUPLICATE_ASSET_MUTATION,
          variables: {
            sourceAssetId: this.sourceAssetCurrent.id,
            targetAssetIds: this.targetAssetsSelected.map(({ id }) => id),
            properties,
          },
        })

        this.$emit('reload')

        FlashMessages.$emit('success', `Successfully duplicated`, {
          timeout: 3000,
        })
      } catch (error) {
        FlashMessages.$emit('error', error)
      } finally {
        this.isSubmitLoading = false
      }
    },
  },
  computed: {
    canSubmit() {
      const isAnyLoading = this.isSourceAssetLoading || this.isSourceAssetLoading || this.isSubmitLoading

      return !isAnyLoading && this.sourceAssetCurrent && this.targetAssetsSelected.length >= 1
    },
    isSourceAssetLoading() {
      return this.$apollo.queries.sourceAssetCurrent.loading
    },
    sourceAssetRouteId() {
      return this.$route.params.id
    },
    sourceAssetsQueryVariables() {
      const where = {}

      if (this.sourceAssetRouteId) {
        where.id_not_in = [this.sourceAssetRouteId]
      }

      if (this.searchQuery.length) {
        where.OR = [
          { name_contains: this.searchQuery },
          { name_contains: this.searchQuery.toUpperCase() },
          { name_contains: this.searchQuery.toLowerCase() },
        ]
      }

      return {
        first: 200,
        where,
      }
    },
  },
  apollo: {
    sourceAssetCurrent: {
      query: SOURCE_ASSET_CURRENT_QUERY,
      variables() {
        return {
          where: {
            id: this.sourceAssetRouteId,
          },
        }
      },
      result({ data }) {
        this.sourceAssetCurrent = data?.sourceAssetCurrent
      },
    },
    sourceAssets: {
      query: SOURCE_ASSETS_QUERY,
      watchLoading(isLoading) {
        this.isSourceAssetsLoading = isLoading
      },
      variables() {
        return this.sourceAssetsQueryVariables
      },
      result({ data }) {
        const sourceAssets = data?.sourceAssets || []
        this.sourceAssetOptions = [...sourceAssets]
      },
    },
  },
}
</script>
