var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("Target Assets")]), _c('Multiselect', {
    attrs: {
      "loading": _vm.isLoading,
      "options": _vm.targetAssets,
      "value": _vm.targetAssetsSelected,
      "multiple": true,
      "preserveSearch": true,
      "trackBy": "id",
      "label": "name",
      "placeholder": 'Type to search',
      "internalSearch": false
    },
    on: {
      "select": _vm.handleSelect,
      "remove": _vm.handleRemove,
      "search-change": _vm.handleSearchChange
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }