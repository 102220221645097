<template>
  <FormWrapper>
    <label>Id</label>
    <input :value="asset.id" :disabled="true" />
    <label>Name</label>
    <input :value="asset.name" :disabled="true" />
  </FormWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const FormWrapper = styled('div')`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
  margin-bottom: 0.5rem;

  & > label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  & > input {
    padding: 0.5rem;
  }
`

export default {
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormWrapper,
  },
}
</script>
