var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("Select entities to copy")]), _vm._l(_vm.duplicatedEntities, function (entity) {
    return _c('SelectEntityStyled', {
      key: entity.value,
      class: {
        enabled: entity.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.handleSelect(entity);
        }
      }
    }, [_c('CheckIcon'), _c('span', [_vm._v(_vm._s(entity.label))])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }