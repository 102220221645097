<template>
  <div>
    <h4>Select entities to copy</h4>
    <SelectEntityStyled
      v-for="entity in duplicatedEntities"
      :key="entity.value"
      :class="{ enabled: entity.isSelected }"
      @click="handleSelect(entity)"
    >
      <CheckIcon />
      <span>{{ entity.label }}</span>
    </SelectEntityStyled>
  </div>
</template>

<script>
import { buttonReset, flexCenter } from '@styles/mixins'
import chroma from 'chroma-js'
import { styled } from '@egoist/vue-emotion'
import { CheckIcon } from 'vue-feather-icons'

const SelectEntityStyled = styled('button')`
  ${flexCenter}
  ${buttonReset}
  background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  transition: background-color 0.1s linear;
  .feather {
    opacity: 0.1;
    color: ${p => p.theme.colors.black};
    margin-right: 0.25rem;
  }
  &:hover {
    .feather {
      color: ${p => p.theme.colors.archonBlue};
      opacity: 1;
    }
  }
  &.enabled {
    background: ${props => chroma(props.theme.colors.archonBlue).alpha(0.2).css()};
    .feather {
      color: ${p => p.theme.colors.white};
      opacity: 1;
    }
  }
`

export default {
  props: {
    duplicatedEntities: {
      type: Array,
      required: true,
    },
  },
  components: {
    SelectEntityStyled,
    CheckIcon,
  },
  methods: {
    handleSelect(selectedEntity) {
      const isSelectedToSet = !selectedEntity.isSelected

      const shouldPreventSubsidiariesSelect = selectedEntity.value === 'subsidiaries' && !this.isOrganizationsSelected
      const shouldDeselectSubsidiariesWithOrganizations =
        selectedEntity.value === 'organizations' && isSelectedToSet === false && this.isSubsidiariesSelected

      if (shouldPreventSubsidiariesSelect) {
        return
      }

      if (shouldDeselectSubsidiariesWithOrganizations) {
        const updatedEntities = this.duplicatedEntities.map(entity => {
          if (entity.value === 'organizations' || entity.value === 'subsidiaries') {
            entity.isSelected = false
          }

          return entity
        })

        this.$emit('update:duplicatedEntities', updatedEntities)
        return
      }

      const updatedEntities = this.duplicatedEntities.map(entity => {
        if (entity.value === selectedEntity.value) {
          entity.isSelected = isSelectedToSet
        }

        return entity
      })

      this.$emit('update:duplicatedEntities', updatedEntities)
    },
  },
  computed: {
    isSubsidiariesSelected() {
      return this.duplicatedEntities.find(({ value }) => value === 'subsidiaries')?.isSelected
    },
    isOrganizationsSelected() {
      return this.duplicatedEntities.find(({ value }) => value === 'organizations')?.isSelected
    },
  },
}
</script>
