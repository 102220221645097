<template>
  <div>
    <h4>Target Assets</h4>
    <Multiselect
      :loading="isLoading"
      :options="targetAssets"
      :value="targetAssetsSelected"
      :multiple="true"
      :preserveSearch="true"
      trackBy="id"
      label="name"
      :placeholder="'Type to search'"
      :internalSearch="false"
      @select="handleSelect"
      @remove="handleRemove"
      @search-change="handleSearchChange"
    />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { debounce } from 'lodash'

import TARGET_ASSETS_QUERY from '#/graphql/operations/assets/targetAssets.gql'

export default {
  props: {
    sourceAssetId: {
      type: String,
      required: true,
    },
    targetAssetsSelected: {
      type: Array,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      isLoading: false,
      targetAssets: [],
      searchQuery: '',
    }
  },
  watch: {
    sourceAssetId: {
      handler() {
        this.searchQuery = ''
        this.targetAssets = []
        this.$emit('update:targetAssetsSelected', [])
      },
    },
  },
  methods: {
    handleSearchChange: debounce(function (searchQuery) {
      this.searchQuery = searchQuery
    }, 300),
    handleSelect(asset) {
      this.$emit('update:targetAssetsSelected', [...this.targetAssetsSelected, asset])
    },
    handleRemove(asset) {
      this.$emit(
        'update:targetAssetsSelected',
        this.targetAssetsSelected.filter(({ id }) => id !== asset.id),
      )
    },
  },
  computed: {
    queryVariables() {
      const where = {}

      let id_not_in = []

      if (this.sourceAssetId) {
        id_not_in.push(this.sourceAssetId)
      }

      if (this.targetAssetsSelected.length) {
        id_not_in = [...id_not_in, ...this.targetAssetsSelected.map(({ id }) => id)]
      }

      if (id_not_in.length) {
        where.id_not_in = id_not_in
      }

      if (this.searchQuery.length) {
        where.OR = [
          { name_contains: this.searchQuery },
          { name_contains: this.searchQuery.toUpperCase() },
          { name_contains: this.searchQuery.toLowerCase() },
        ]
      }

      return {
        first: 200,
        where,
      }
    },
  },
  apollo: {
    targetAssets: {
      query: TARGET_ASSETS_QUERY,
      watchLoading(isLoading) {
        this.isLoading = isLoading
      },
      variables() {
        return this.queryVariables
      },
    },
  },
}
</script>
